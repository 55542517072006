@import 'styles/index';

.mi-navigation-header {
  background-color: get-color('indigo');
  display: flex;
  font-family: $proxima;
  height: 60px;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  &--hide-mobile {
    display: none;
  }

  .header-logo {
    cursor: pointer;
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 14px;
    padding:0;
    flex: 0 1 41px;
    &__img {
      height: 30px;
      width: auto;
    }

    &--bottom {
      display: none;
    }

    &.centered {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .navigation-header-link {
    background-color: transparent;
    border: 0;
    color: get-color(slate-grey);
    padding: 20px 5px;
    font-family: $proxima;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-smooth: auto;
    -webkit-font-smoothing: antialiased;
    width: auto;

    font-size: .875rem;
    &.selected-route {
      color: get-color('white');
    }
  }

  .navigation-header-user-container {
    display: none;
    position: absolute;
    right: 40px;
    top: 5px;
    width: 30px;
    padding:0;
  }
}

.navigation-header-link__text {
  display: block;
  font-size: 0.75rem;
}



@media screen and (min-width: 764px) {
  .mi-navigation-header .navigation-header-user-container {
    display: block;
  }

  .mi-navigation-header--hide-mobile {
    display: block;
  }

  .navigation-header-link__text {
    font-size: 0.875rem;;
  }

  .mi-navigation-header .navigation-header-link {
    padding: 0;
  }
  .row a.navigation-header-link,
  .navigation-header-link {
    margin: 20px 20px 0 0;
  }

  .mi-navigation-header .header-logo {
    display: inline-block;
  }
}


@media screen and (min-width: 764px) {
  .mi-navigation-header .header-logo {
    &__img {
      height: 35px;
    }
    margin-right: 40px;
  }
}
