@import 'styles/index';
.progress-accordion-header-container {
    display: flex;
    align-items: center;
    position: relative;
    .progress-accordion-status-label {
        margin-right: 40px;
        font-size: .625rem;
        text-transform: uppercase;
        margin-left: auto;

        &--complete {
            color:get-color('confirmation');
        }

        &--in-progress,
        &--started {
            color:get-color('tangerine');
        }

        &--notstarted {
            color: rgba(0, 0, 0, .25);
        }
    }

    .icon {

        position: absolute;
        right: -12px;
        top: 0;

        &.status-indicator {
            position: relative;
            top:auto;
            right:auto;
            width: 12px;;
            height: 12px;
            flex: 0 0 12px;
            border:0;

            svg {
                position: absolute;
                top:24.5%;
                left:24.5%;
                transform: translate(-0.5px, .5px);
            }
        }
    }

    p {
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left;
    }
}
