@import 'styles/index';

.notifications-dropdown {
  background-color: get-color('white');
  border: 1px solid get-color(mercury);

  @include media-query(764) {
    &::before {
      content: url('data:image/svg+xml;charset=UTF-8, <svg width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.63176 0.707107C7.02228 0.316583 7.65545 0.316583 8.04597 0.707107L14.4099 7.07107L7.73081 13.7502C7.51435 13.9667 7.16339 13.9667 6.94692 13.7502L0.267799 7.07107L6.63176 0.707107Z" fill="white"/></svg>');
      display: block;
      width: 22px;
      height: 10px;
      margin: 10px 5px 0 10px;
      top: -19px;
      position: absolute;
      left: 255px;
      z-index: 2;
    }
  }

  $mobileBottomNavHeight: 85px;

  contain: layout;
  height: calc(100vh + 30px - #{$mobileBottomNavHeight});
  left: auto;
  max-height: calc(100vh + 30px - #{$mobileBottomNavHeight});
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -30px;

  width: 100vw;
  will-change: opacity, transform;
  z-index: 50;

  &[open] {
    pointer-events: auto;
  }

  &__inner-wrap {
    position: relative;
    z-index: 1;
  }

  .notifications-container {
    height: auto;
    max-height: calc(100vh - 135px);
    overflow: auto;
  }

  .notifications-dropdown-card-container {
    position: relative;

    &:not(:first-child) {
        border-top: 1px solid get-color('neutral-medium');
    }

    &.unread {
        &:before {
            width: 4px;
            height: 100%;
            background: get-color('err');
            content: ' ';
            display: block;
            position: absolute;
            z-index: 1000;
        }
    }

    &.read {
        &:before {
            width: 2px;
            height: 100%;
            background: transparent;
            content: ' ';
            display: block;
            position: absolute;
            z-index: 1000;
            transition : all .5s linear;
        }
    }
  }

  .notifications-header {
    padding-left: 20px;
    padding-top: 20px;
    border-bottom: 1px solid get-color('neutral-medium');
    display: flex;

    .mobile-notifications-dropdown-close-button {
        display: none;
    }

    .notifications-total {
        color: get-color('white');
        background-color: get-color('err');
        margin-left: 10px;
        border-radius: 50%;
        font-size: 12px;
        width: 6%;
        height: 5%;
        text-align: center;
        line-height: 19px;
        min-height: 20px;
        min-width: 20px;
    }
  }
}



.notifications-dropdown.fade-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.notifications-dropdown.fade-enter-active {
  opacity: 1;
  transform: none;
  transition: opacity 200ms, transform 200ms;
}

.notifications-dropdown.fade-enter-done {
  opacity: 1;
  pointer-events: auto;
}

.notifications-dropdown.fade-exit {
  opacity: 1;
}

.notifications-dropdown.fade-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 200ms, transform 200ms;
}

.notifications-dropdown.fade-exit-done {
  opacity: 0;
}

@media screen and (max-width:764px) {
    .notifications-dropdown {
        overflow: scroll;
        background-color: rgba(0, 0, 0, 0.85);
        position: fixed;
        padding: 50px 20px 20px 20px;

        &.notifications-dropdown--mobile {

            &.fade-enter {
                opacity: 0;
            }

            &.fade-enter-active {
                opacity: 1;
                transition: opacity 200ms;
            }

            &.fade-enter-done {
                opacity: 1;
            }

            &.fade-exit {
                opacity: 1;
            }

            &.fade-exit-active {
                opacity: 0;
                transition: opacity 200ms;
            }


        }

        .notifications-dropdown__inner-wrap {
            border-radius: 3.73695px;
            overflow: hidden;
            max-height: 92%;
            height: auto;
        }

        .notifications-container {
            overflow: scroll;
            max-height: calc(100vh - 280px);
        }

        .notifications-header {
            padding-left: 10px;
            padding-top: 12px;
            flex-wrap: wrap;
            background-color: get-color('white');
            height: 95px;

            .mobile-notifications-dropdown-close-button {
                display: block;
                background-color: transparent;
                border: none;
                padding: 0;
                width: 100%;
                margin-bottom: 20px;
                text-align: left;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .text {
                padding-left: 8px
            }
        }
    }
}

@include media-query(650) {
  .notifications-dropdown{
    border-radius: 4px;
    height: auto;
    max-width: 350px;
    max-height: 367px;
    right: calc((100vw - 680px) / 2 + 15px);
    top: 63px;
  }
}

@include media-query(990) {
  .notifications-dropdown {
    right: calc((100% - 990px) / 2 + 30px);
  }
}

@include media-query(1200) {
  .notifications-dropdown {
    right: calc((100% - 912px) / 2);
  }
}
