@import 'styles/index';

.user-profile-dropdown {
  background-color: get-color('white');
  border: 1px solid get-color(mercury);

  @include media-query(764) {
    &::before {
      content: url('data:image/svg+xml;charset=UTF-8, <svg width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.63176 0.707107C7.02228 0.316583 7.65545 0.316583 8.04597 0.707107L14.4099 7.07107L7.73081 13.7502C7.51435 13.9667 7.16339 13.9667 6.94692 13.7502L0.267799 7.07107L6.63176 0.707107Z" fill="white"/></svg>');
      display: block;
      width: 22px;
      height: 10px;
      margin: 10px 5px 0 10px;
      top: -19px;
      position: absolute;
      left: 300px;
      z-index: 2;
    }
  }

  $mobileBottomNavHeight: 85px;

  contain: layout;
  height: calc(100vh - #{$mobileBottomNavHeight});
  left: auto;
  max-height: calc(100vh - #{$mobileBottomNavHeight});
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  width: 100vw;
  will-change: opacity, transform;
  z-index: 50;

  &[open] {
    pointer-events: auto;
  }

  &__add-remove-container {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    > * {
      flex: 1 1 auto;
      margin:0;
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        margin-left: 4px;
      }
      &:only-child {
        margin:0;
      }
    }
  }

  &--page {
    max-height: none;
    position: relative;
    opacity: 1;
    pointer-events: auto;
    transition: none;
    transform:none;
    height: auto;
  }

  &__name {
    background: get-color('neutral-light');
    border-bottom: 1px solid get-color('neutral-medium');
    padding: 20px;
    color:get-color('indigo');
    text-align: center;
    position: relative;
  }

  @include media-query(764) {
    &__name {
      background: get-color('white');
      color: get-color(lunar-green);
      text-align: left;
    }
  }

  &__footer {
    background: get-color(athens-gray);
    padding: 0 20px;
  }

  &::backdrop {
    display: none;
  }

  &__content-wrap {
    border-bottom: 1px solid get-color(neutral-medium);
    padding: 20px;

    .button {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__inner-wrap {
    position: relative;
    z-index: 1;
  }
}

.fade-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transform: none;
  transition: opacity 200ms, transform 200ms;
}

.fade-enter-done {
  opacity: 1;
  pointer-events: auto;
}

.user-profile-dropdown.fade-exit {
  opacity: 1;
}

.user-profile-dropdown.fade-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 200ms, transform 200ms;
}

.user-profile-dropdown.fade-exit-done {
  opacity: 0;
}


@include media-query(650) {
  .user-profile-dropdown {
    border-radius: 4px;

    max-width: 350px;
    max-height: calc(100vh - 70px);
    overflow: auto;


    &--modal {
      height: auto;
      overflow: visible;
    }
    &__outer-wrap {
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      overflow: auto;
      max-height: calc(100vh - 72px);
      position: relative;
      background: get-color('white');
      z-index: 3;
    }

    right: 35px;
    top: 63px;


    &--page {
      right:auto;
      top:auto;
      max-width: none;
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 763px) {
  .user-profile-dropdown--modal {
    display: none;
  }
}

@include media-query(990) {
  .user-profile-dropdown {
    right: calc((100% - 990px) / 2 + 30px);
  }
}

@include media-query(1200) {
  .user-profile-dropdown {
    right: calc((100% - 912px) / 2);
  }
}


.notification-settings-modal {
  background: rgba(0, 0, 0, 0.65);
  text-align: center;

  .modal-content {
    border-radius: 16px;
    height: 682px;
    margin: auto;
    max-width: 700px;
    width: 100%;

    .modal-header {
      border: 0;

      .close {
        left: 40px;
        margin: 0;
        opacity: 1;
        padding: 0;
        position: absolute;
        top: 35px;
        border: 1px solid get-color(silver-chalice);
        z-index: 99;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        overflow: hidden;

        &:hover {
          background-color: get-color(alto);
          color: get-color('black');
        }
        &:active,
        &:focus {
          outline-color: get-color(loblolly);
        }

        span {
          display: none;
        }

        &::before {
          position: absolute;
          transform: translate(-50%, -50%);
          top:50%;
          width: 100%;
          height: 100%;
          left:50%;
          content: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="21" cy="21" r="21" fill="none"/><path d="M27.5625 27.5625L14.4375 14.4375" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M27.5625 14.4375L14.4375 27.5625" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }
      }

    }

  }

  .modal-body {
    margin: auto;
    max-height: 600px;
    max-width: 680px;
    padding: 0;
    position: relative;
    width: 100%;

    .notification-settings-container {
      height: 648px;
      margin: auto;
      max-width: 480px;
      width: 100%;
      margin-top: 40px;

      &:focus {
        outline: none;
      }

      .icon[data-name="successCheckmark"] {
        background-color: get-color('confirmation');
      }

      .notification-settings {
        &__icon {
          color:get-color('indigo');
          position: relative;

          & + p {
            font-weight: 600;
          }
        }
        &__alert-dot {
          position: absolute;
          top:40px;
          border-radius: 50%;
          left:50%;
          height: 15px;
          z-index: 2;
          width: 15px;
          background-color: get-color('err');
          transform: translate(calc(-50% + 8px), calc(-50% - 18px));
        }
      }

      .notification-settings-content-wrapper {
        height: 300px;
        margin: auto;
        max-width: 350px;
        position: relative;
        width: 100%;

        @include media-query(1025) {
          max-width: 400px;
        }

        .notification-settings-content {
          bottom: 40px;
          position: absolute;

          .text--large {
            font-weight: 600;
            line-height: 28px;
          }

          .text--medium {
            font-weight: 500;
            line-height: 19px;
          }
        }

        .button {
          height: 42px;
          margin: 20px auto;
          width: 226px;
        }
      }
    }

  }
}
