@import 'styles/index';

.checklist-dropdown {
  background-color: get-color('white');
  border: 1px solid get-color(mercury);

  @include media-query(764) {
    &::before {
      content: url('data:image/svg+xml;charset=UTF-8, <svg width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.63176 0.707107C7.02228 0.316583 7.65545 0.316583 8.04597 0.707107L14.4099 7.07107L7.73081 13.7502C7.51435 13.9667 7.16339 13.9667 6.94692 13.7502L0.267799 7.07107L6.63176 0.707107Z" fill="white"/></svg>');
      display: block;
      height: 10px;
      left: 195px;
      margin: 10px 5px 0 10px;
      position: absolute;
      top: -19px;
      width: 22px;
      z-index: 2;
    }
  }


  $mobileBottomNavHeight: 85px;

  contain: layout;
  height: calc(100vh - #{$mobileBottomNavHeight});
  left: auto;
  max-height: calc(100vh - #{$mobileBottomNavHeight});
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  width: 100vw;
  will-change: opacity, transform;
  z-index: 50;

  .primary-button {
    margin:0 20px 15px;
    max-width: calc(100% - 40px);
  }

  &--page {
    position: relative;
    top:auto;
    right:auto;
    opacity: 1;
    pointer-events: auto;
    height:auto;
    max-height: none;
    border-radius: $global-border-radius;
    margin: 0 20px;
    max-width: 100%;
    max-width: calc(100% - 40px);
  }

  &[open] {
    pointer-events: auto;
  }

  &__inner-wrap {
    position: relative;
    z-index: 1;
  }
}

.checklist-task-container {
    padding-bottom: 10px;

    .checklist-empty-message {
      background: get-color('portal-bg-color');
      border-radius: 3px;
      width: 90%;
      margin: auto;
      padding: 40px;
      text-align: center;

      .text {
        margin-bottom: 0px;
      }
    }
}

.checklist-header {
    padding: 20px;

    border-bottom: 1px solid get-color('neutral-medium');
    margin-bottom: 15px;

    &--page {
      border:0;
      margin-bottom: 0px;
    }
}



.fade-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transform: none;
  transition: opacity 200ms, transform 200ms;
}

.checklist-dropdown.fade-enter-done {
  pointer-events: auto;
  opacity: 1;
}

.checklist-dropdown.fade-exit {
  opacity: 1;
}

.checklist-dropdown.fade-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 200ms, transform 200ms;
}

.checklist-dropdown.fade-exit-done {
  opacity: 0;
}


@include media-query(650) {
  .checklist-dropdown{
    border-radius: 4px;
    height: auto;
    max-width: 350px;
    max-height: calc(100vh - 70px);
    right: 35px;
    top: 63px;
    &--page {
      max-width: calc(100% - 40px);
      margin: 0 20px;
      right: auto;
      top:auto;
    }
  }
}

@include media-query(750) {
  .checklist-dropdown{

    &--page {
      max-width: calc(100% - 40px);

    }
  }
}

@include media-query(990) {
  .checklist-dropdown {
    right: calc((100% - 990px) / 2 + 50px);
  }
}

@include media-query(1200) {
  .checklist-dropdown {
    right: calc((100% - 912px) / 2);
  }
}
