@import '../../../../styles/index';

.photo-nav-left-button,
.photo-nav-right-button {
  background-color: transparent;
  border: 0;
  padding: 0;

  &--disabled {
    pointer-events: none;
  }

  &:focus,&:active {
    outline: 0;
  }
}

.photo-modal {
  overflow: hidden;
  border:0;
  height: 100%;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  padding:0;
  position: fixed;
  width: 100%;
  top:0;
  left:0;
  background: $modalbg;
  color: get-color('white');
  pointer-events: none;
  display: none;

  &__content {
    position: relative;
    height: 100%;
    max-width: 979px;
    margin:auto auto;
    flex: 0 0 100%;
  }

  .close-button {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .25);
    top:5px;
    left:5px;
    width:32px;
    height: 32px;
  }

  .close-button__inner::before,
  .close-button__inner::after {
    background-color: white;
  }


  &[open],
  &[open="true"],
  &--open {
    pointer-events: auto;
    display: flex;
    z-index: 10001;
  }
}



.thumbnav {
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.25);
  bottom:0;
  width: 100%;
  height: 64px;
  padding: 12px 0;
  position: absolute;
  transition:  opacity .3s, transform .3s;
  max-width: 979px;
  &--hide {
    opacity: 0;
    transform: translateY(100%);
  }

  .slick-current {
    opacity: .4;
  }
}

.photo-details-wrapper {
  background-color: transparent;
  @include nakedbutton;
  height: 100%;
}

.photo-modal--firefox-with-share {
  display: none !important;
  pointer-events: none;
}

.photo-action {

  background-color: transparent;
  padding:0;
  border:0;
  color: get-color('white');
  margin-left: 10px;
  white-space: nowrap;
  &:active,
  &:hover {
    text-decoration: underline;
    color: get-color('white');
  }
}

.photo-nav-left-button {
  text-align: left;
}
.photo-nav-right-button {
  text-align: right;
}


.photo-modal {
  .thumbnav {
    z-index: 2;
    .slick-track,
    .slick-slider {
      height: 40px;
    }
    .photo-details-wrapper {
      height: 40px;
    }
  }
  .photoslider {
    z-index: 1;
  }
}


.photo-nav-container {
  background: linear-gradient(180deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .2) 15%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, .8) 100%);
  border:0;
  bottom: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height:100%;
  transition: background .3s;

  &--no-bg {
    background: transparent;
  }


  display: flex;
  max-width: 979px;

  &--desktop {
    max-height: 100%;
  }

  top:0px;
  z-index: 3;

  &--disabled {
    pointer-events: none;
    .photo-actions {
      pointer-events: auto;;
    }

    .photo-nav-left-button,
    .photo-nav-right-button {
      opacity: 0;
    }
  }
}

.photo-modal {
    img {
      height: 100%;
      margin: auto;
      max-height: 100vh;
      object-fit: contain;
      object-position: center center;
      width: 100%;
    }

    &:focus,
    &:active {
      outline: 0;
    }

    .photo-nav-pagination {
      .text {
        padding: 10px;
        text-align: center;
      }
    }

    .photo-nav-button-container {
      position: relative;
      width: 100%;
      flex: 0 0 100%;
      display: flex;
      flex-wrap: wrap;
      max-height: 100%;
      max-width: 100%;
      border:0;
      width: 100%;
    };


    .photo-nav-left-button,
    .photo-nav-right-button {
      order:4;
      flex: 0 0 50%;
      align-self: center;
      position: absolute;
      height: calc(100% - 135px);
      width: 50%;
      top:35px;
    }

    .photo-nav-left-button {
      left:0
    }
    .photo-nav-right-button {
      right:0
    }

    .photo-nav-pagination {
      order:5;
      flex: 0 0 100%;
      font-size: 16px;
    }



    .photo-nav-container {

      .primary-icon-only-button {
        border: 0;
        padding: 0;
      }


      .icon {
        height: initial;
      }
    }


    .button {
      background: white;
      border: 0;
      border-radius: 50px;
      display: inline-block;
      height: 42px;
      margin: 0 10px;
      width: 42px;
    }



    .slick-slider {
      max-height: 100vh;
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      & + .slick-slider {
        position: absolute;
        bottom: 0;
        max-height: 50px;
        top: auto;
        .slick-slide {
          width: 25%;
          height: 100%;
          opacity: 0.4;
        }
      }
      .slick-arrow {
        display: none;
      }
    }

    .photo-nav-container {
      .photo-caption.text {
        margin-left: 10rem;
      }
    }


}

.photo-caption {
  order:5;
  flex: 0 0 100%;
  padding:20px 20px 0;
  align-self: flex-end;
  .text {
    font-size: 1rem;
  }
  position: absolute;
  bottom:100px;
  left:0;

  transition:  transform .3s;
  &--shift {
    transform: translateY(64px);
  }
}

.photo-actions {
  flex: 0 0 100%;
  text-align: right;
  padding: 6px 20px 0 0;
  max-height: 35px;
  transition:  transform .3s;
  &--hidden {
    transform: translateY(-125%);
  }
}


.photo-nav-pagination {
  padding: 20px 20px 70px;
  align-self: flex-end;
  transition:  transform .3s;
  &--shift {
    transform: translateY(64px);
  }
}

.photo-modal {
  .photoslider {
    padding-top: 0px;
    position: relative;
    height: 100%;
  }

  .slick-list,
  .slick-track {
    max-height: 100vh;
    height: 100%;
  }
  .slick-slide {
    position: relative;
    > div {
      height: 100%;
    }
  }
}

@include media-query(764) {
  .thumbnav--desktop {
    backdrop-filter: none;
    opacity: 0;
    background-color: transparent;
    pointer-events: none;
    display: none;
  }
  .photo-modal .slick-track {
    max-height: none;
  }

  .photo-action {
    margin-left: 25px;

  }
  .photo-nav-pagination--shift,
  .photo-caption--shift {
    transform: none;
  }
  .photo-nav-pagination {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 20px;

  }
  .photo-actions {
    order:2;
    padding:20px;
    flex: 1 1 20%;
    max-height: none;

  }
  .photo-caption {
    order:1;
    flex: 0 1 80%;
    .text {
      font-size: 1.25rem;
    }
  }
  .photo-nav-left-button,
  .photo-nav-right-button {
    position: static;
    left:auto;
    right:auto;
    height: auto;
  }

}

@include media-query(764) {
  .photo-modal__content {
    background-color: get-color('black');
    height: auto;
  }
  .photo-modal--mobile .photo-modal__content {
    height: 100%;
  }
  .photo-modal .close-button {
    top:-0;
    left:-45px;
    border-color: #1d1d1d;
    background-color: #1d1d1d;
    width:40px;
    height:40px;
  }
  .photo-modal--true-mobile {
    max-height: 100% !important;
  }
  .photo-modal.photo-modal--true-mobile button {
    top:5px !important;
    left:10px;
  }
  .photo-modal.photo-modal--true-mobile .thumbnav {
    position: fixed;
  }

  .photo-modal .photoslider {
    min-height: 0;
    max-height: calc(100% - 90px);
  }
  .photo-modal--true-mobile {
    max-height: 100% !important;
  }

  .photo-modal--desktop .photoslider {
    min-height: calc(100vh - 70px);
    margin: auto auto;
  }

  .photo-modal .photo-nav-container .photo-caption {
    padding-left: 20px;
  }

  .photo-modal.photo-modal--desktop  {
    .thumbnav .slick-slider {
      position: relative;
      height: 100%;
    }
  }
  $closeSize:42px;
  .photo-modal {
    .close {
      width: $closeSize;
      height:$closeSize;
    }
  }

  .photo-modal {
    .photo-nav-left-button,
    .photo-nav-right-button {
      top:auto;
      height: auto;
      position: relative;
      left:auto;right:auto;
    }
  }

  .photo-caption {
    position: relative;
    bottom:auto;
    left:auto;
    align-self: flex-start;
    padding-left: 90px;
  }
  @media screen and (orientation: portrait) {
    .photo-caption {
      padding-left: 0;
    }

  }
  .photo-modal--mobile {

  }
  .photo-modal {
    &.photo-modal--mobile {
      .photo-nav-container {
        max-height: calc(100vh - 90px);
        &--mobile {
          max-height: calc(100% - 90px);
        }
        &--true-mobile {
          max-height: 100vh !important;
        }
        bottom: 10px;
        top: initial;
      }

      .slick-slider {
        top: 0;
      }

      .photo-details-wrapper {
        img {
          height: calc(100vh - 8rem);
          width: 100%;
        }
      }

    }

    &.photo-modal--desktop {
        .photo-details-wrapper {
          height: calc(100vh - 18rem);

          img {
            height: 100%;
            max-width: 100%;
          }
        }

        .slick-list {
          height: 100%;
          max-height: none;

        }

        .slick-track {
          height: 100%;
        }

        .photo-details-wrapper {
          height: 100%;
          position: absolute;
          width: 100%;
        }

        .slick-slide {
          position: relative;

          > div {
            height: 100%;
            position: absolute;
            width: 100%;
          }
        }

        .slick-slider {
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
          max-height: none;

          .slick-arrow {
            display: none;
          }
        }
      }
    }

    .photoslider {
      height: calc(100vh - 90px);
      padding-top: 0;
      border-radius: $global-border-radius;


      &--mobile {
        height: 100vh;
        max-height: 100%;
      }
    }



    .photo-nav-container {
      .photo-caption {
        max-width: 80%;
        max-width: calc(100% - 400px);
        &.text {
          font-size: 20px;
          margin-left: 0;
        }
      }
    }
}

@media only screen
  and (min-width: 764px)
  and (max-height:650px)
  and (orientation: landscape) {
  .photo-actions {
    min-width: 320px;
  }
  .photo-modal.photo-modal--mobile .photo-nav-container {
    bottom: auto;
    top:0;
  }
  .photo-modal .photo-nav-container .photo-caption {
    max-width: calc(100% - 320px);
    &--mobile {
      padding-left: 90px;
    }
    &--shift {
      transform: none;
    }
  }

  .photo-modal.photo-modal--mobile .photo-details-wrapper img,
  .photo-modal .slick-list,
  .photo-modal .slick-track,
  .photo-modal .slick-slider {
    max-height: 100vh;
    height: 100%;
  }
}

@media screen and (min-width:764px) and (orientation: portrait) {
  .photo-modal .photoslider {
    min-height: calc(100vh - 150px);
    max-height: 100%
  }

  .photo-modal.photo-modal--mobile .photo-nav-container {
    top:0;
    bottom:auto;
    max-height: 100%;
  }
}


@media screen and (min-width:764px) and (orientation: landsape) {
  .photo-modal.photo-modal--mobile .photo-details-wrapper img,
  .photo-modal.photo-modal--mobile .photo-details-wrapper img {
    min-height: 80vh !important;
  }
}

